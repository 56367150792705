import React from 'react';
import Link from 'next/link';
// import Image from 'next/image';

import MaxWidthContainer from 'components/MaxWidthContainer';

const Footer = (props) => {
  const { ...restProps } = props;

  return (
    <>
      <footer className="footer-frame">
        <MaxWidthContainer className="h-full flex-wrap">
          <div className="footer-inner block grid-cols-1 py-12 lg:grid lg:grid-cols-6">
            <div className="footer-column col-span-2 grid grid-flow-row auto-rows-max gap-y-4">
              <img src="/images/sorra.png" className="footer-logo"></img>
              <div className="grid auto-cols-max grid-flow-col gap-x-5">
                <img
                  src="/svgs/footer-sns-instagram.svg"
                  className="cursor-pointer"
                  onClick={() =>
                    window.open(
                      'https://www.instagram.com/sorra.beauty',
                      '_blank'
                    )
                  }
                />
                <img
                  src="/svgs/footer-sns-facebook.svg"
                  className="cursor-pointer"
                  onClick={() =>
                    window.open('https://www.facebook.com/sorranet', '_blank')
                  }
                />
                {/* <img src='/svgs/footer-sns-youtube.svg'></img> */}
              </div>
            </div>
            <div className="footer-column grid grid-flow-row auto-rows-max gap-y-5">
              <span className="footer-links-header text-sm font-semibold">
                關於我們
              </span>
              <a
                href="/about"
                className="text-base font-normal text-sorra-gray-800"
              >
                認識SORRA
              </a>
              <a
                href="/membership"
                className="text-base font-normal text-sorra-gray-800"
              >
                會員制度
              </a>
              <a
                href="https://blog.sorra.net/"
                target="_blank"
                className="text-base font-normal text-sorra-gray-800"
              >
                社群文章
              </a>
              <a
                href="mailto:info@sorra.net"
                className="text-base font-normal text-sorra-gray-800"
              >
                聯絡我們
              </a>
            </div>
            <div className="footer-column grid grid-flow-row auto-rows-max gap-y-5">
              <span className="footer-links-header text-sm font-semibold">
                資訊
              </span>
              <a
                href="/ranking"
                className="text-base font-normal text-sorra-gray-800"
              >
                全部排行榜
              </a>
              <a
                href="/rewards?tab=code"
                className="text-base font-normal text-sorra-gray-800"
              >
                優惠碼合集
              </a>
              <Link
                href="/FAQ"
                className="text-base font-normal text-sorra-gray-800"
              >
                常見問題
              </Link>
            </div>
            <div className="footer-column grid grid-flow-row auto-rows-max gap-y-5">
              <span className="footer-links-header text-sm font-semibold">
                探索
              </span>
              <a
                href="mailto:sales@sorra.net"
                className="text-base font-normal text-sorra-gray-800"
              >
                商業或媒體聯絡
              </a>
              <a
                href="/nominate"
                className="text-base font-normal text-sorra-gray-800"
              >
                新增產品
              </a>
            </div>
            <div className="footer-column grid grid-flow-row auto-rows-max gap-y-5">
              <span className="footer-links-header text-sm font-semibold">
                私隱與條款
              </span>
              <a
                href="https://sorra.notion.site/Private-Policy-sorra-net-14-Aug-2023-c8b664a4d40a42f0b35af9e4f416357a?pvs=4"
                target="_blank"
                className="text-base font-normal text-sorra-gray-800"
              >
                私隱政策
              </a>
              <a
                href="https://sorra.notion.site/Website-Terms-and-Conditions-sorra-net-14-Aug-2023-e99d70976b7842c38495ee97a6074f13?pvs=4"
                target="_blank"
                className="text-base font-normal text-sorra-gray-800"
              >
                條款及細則
              </a>
            </div>
          </div>
          <div className="footer-bottom flex items-center justify-center border-t border-gray-200 py-8 text-base text-gray-400 lg:py-12">
            Copyright © 2023 Sorra. All rights reserved.
          </div>
        </MaxWidthContainer>
      </footer>
    </>
  );
};

export default Footer;
